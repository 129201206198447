/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { StarFilled, StarOutlined } from '@ant-design/icons'
import { Button, Select, Spin, Table } from 'antd'
import moment from 'moment'
import { func, oneOf, string } from 'prop-types'
import qs from 'query-string'

import {
	getSearchFilterProps,
	getSelectFilterProps,
	Icon
} from '../../components'
import { useAdmins, useRatings } from '../../hooks'
import { uniqueID } from '../../utils'
import ViewUserModal from '../users/view-user-modal'

// eslint-disable-next-line import/no-cycle
import ViewAndRespondModal from './modal'

export const modalModes = {
	viewUser: 'view_user'
}

const STATUS_OPTIONS = [
	{ _id: 'open', title: 'Open' },
	{ _id: 'closed', title: 'Closed' }
]
const ResponseColumn = ({ val, onAction, status }) => {
	return !val && status === 'closed'
		? 'Closed without any response'
		: val || (
				<Button
					type="default"
					htmlType="submit"
					className="edit-btn px-2"
					onClick={onAction}
				>
					<Icon
						variant="reply"
						width={24}
						height={24}
						fill="currentColor"
						className="mr-2"
					/>
					Respond to user
				</Button>
		  )
}
ResponseColumn.propTypes = {
	val: string,
	onAction: func.isRequired,
	status: oneOf(['open', 'closed']).isRequired
}
ResponseColumn.defaultProps = {
	val: ''
}

export default function RatingsPage() {
	const { isLoading, data, totalCount, fetchRatings, respondToRating } =
		useRatings()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	useEffect(() => {
		fetchRatings(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchRatings])

	const { data: adminsData, fetchAdmins } = useAdmins()

	useEffect(() => {
		fetchAdmins()
	}, [fetchAdmins])

	const [modalType, setModalType] = useState(null)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				// delete params.record_id
				delete params.record_type
				setModalType(null)
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return (
			modal_is_open && Object.values(modalModes).includes(mode) && record_id
		)
	}, [queryParams])

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (!value || !value.length) return
			Object.assign(params, { [key]: value[0] })
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchRatings(params)
	}

	const getFilterValue = useCallback(
		(parameter) => {
			return queryParams[parameter] ? [queryParams[parameter]] : undefined
		},
		[queryParams]
	)

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}

	const handleSendStatus = useCallback(
		(status, recordId) => {
			respondToRating({ _id: recordId, status }).then(() =>
				fetchRatings(queryParams)
			)
		},
		[fetchRatings, respondToRating, queryParams]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold max-w-">Ratings List</h1>
			</header>
			<Table
				className="mx-4"
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 219px)',
					x: 768
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					dataIndex="status"
					title="Status"
					key="status"
					width={100}
					{...getSelectFilterProps(
						'response status',
						STATUS_OPTIONS,
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					render={(_val, record) => (
						<Select
							value={_val}
							notFoundContent={isLoading ? <Spin size="small" /> : undefined}
							onChange={(status) => handleSendStatus(status, record._id)}
						>
							{STATUS_OPTIONS.map((option) => (
								<Select.Option value={option._id} key={option._id}>
									{option.title}
								</Select.Option>
							))}
						</Select>
					)}
				/>
				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
					width={190}
					filteredValue={getFilterValue('description')}
					render={(_val) => _val ?? 'Without description!'}
					{...getSearchFilterProps('description')}
				/>
				<Table.Column
					dataIndex={['respondBy', 'name']}
					title="Respond By"
					width={190}
					key="respondBy"
					{...getSelectFilterProps('admins', adminsData, (record) => (
						<Select.Option value={record._id}>{record.name}</Select.Option>
					))}
				/>
				<Table.Column
					dataIndex="respond"
					title="Response"
					key="respond"
					className=""
					width={190}
					filteredValue={getFilterValue('respond')}
					{...getSearchFilterProps('respond text')}
					render={(_val, record) => (
						<ResponseColumn
							val={record.respond}
							onAction={() => {
								setModalVisibility(true, {
									mode: 'view_and_reply',
									record_id: record._id
								})
							}}
							status={record.status}
						/>
					)}
				/>
				<Table.Column
					dataIndex="stars"
					title="Stars"
					key="stars"
					width={100}
					sorter
					sortOrder={getSortOrder('sort_stars')}
					render={(_val) => {
						return (
							Array(_val)
								.fill(StarFilled)
								.concat(Array(5 - _val).fill(StarOutlined))
								// eslint-disable-next-line react/no-array-index-key
								.map((Star, i) => <Star key={`idx-${i}-id-${uniqueID()}`} />)
						)
					}}
				/>
				<Table.Column
					render={(value) =>
						value && moment(value).format('DD-MMM-YYYY, hh:mm A')
					}
					dataIndex="respondAt"
					title="Date updated"
					key="respondAt"
					filteredValue={getFilterValue('respondAt')}
					sorter
					sortOrder={getSortOrder('sort_respondAt')}
					width={190}
					{...getSearchFilterProps('YYYY-MM-DD')}
				/>
				<Table.Column
					render={(value) =>
						value && moment(value).format('DD-MMM-YYYY, hh:mm A')
					}
					dataIndex="createdAt"
					title="Date created"
					width={190}
					key="createdAt"
					sorter
					sortOrder={getSortOrder('sort_createdAt')}
				/>
				<Table.Column
					dataIndex={['child', 'parent', 'name']}
					title="User"
					key="user"
					width={190}
					filteredValue={getFilterValue('user')}
					{...getSearchFilterProps('user')}
					render={(_val, record) => (
						<Button
							type="default"
							className="edit-btn px-2 py-1 mr-2"
							onClick={() => {
								setModalType('view_user')
								setModalVisibility(true, {
									mode: modalModes.viewUser,
									record_id: record.child.parent._id
								})
							}}
						>
							<Icon
								variant="user"
								width={24}
								height={24}
								fill="currentColor"
								className="mr-2"
							/>
							{record.child.parent.name}
						</Button>
					)}
				/>
			</Table>
			<ViewAndRespondModal
				onClose={() => setModalVisibility(false)}
				onRequest={respondToRating}
				queryParams={queryParams}
				setModalType={setModalType}
				setModalVisibility={setModalVisibility}
			/>
			<ViewUserModal
				onClose={handleCloseModal}
				queryParams={queryParams}
				isVisible={modalIsVisible && modalType === 'view_user'}
			/>
		</>
	)
}
