/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} RespondToRatingPayload
 * @property {string} _id
 * @property {string} respond
 */

export default function useRatings() {
	const [data, setData] = useState({
		ratings: [],
		totalCount: 0
	})
	const [isLoading, setLoading] = useState(true)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()
	const fetchRatings = useCallback(
		/**
		 * @param {object} queryParams
		 */
		(queryParams = {}) => {
			return new Promise((resolve, reject) => {
				const {
					page: _page = 1,
					size: _size = 10,
					sort_respondAt,
					sort_stars,
					sort_createdAt,
					...params
				} = queryParams

				params.offset = _page
				params.limit = _size

				if (typeof sort_respondAt === 'number') {
					params.sortKey = 'respondAt'
					params.sortType = sort_respondAt === 1 ? 'desc' : 'asc'
				} else if (typeof sort_stars === 'number') {
					params.sortKey = 'stars'
					params.sortType = sort_stars === 1 ? 'desc' : 'asc'
				} else if (typeof sort_createdAt === 'number') {
					params.sortKey = 'createdAt'
					params.sortType = sort_createdAt === 1 ? 'desc' : 'asc'
				}

				setLoading(true)
				const endpoint = `/admin/child-ratings?${qs.stringify(params, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: ratings, count: total_count } = res.data
						setData({ ratings, totalCount: total_count })
						resolve(ratings)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchRating = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`rating id is not provided`))
				} else {
					axios
						.get(`/admin/child-ratings/${id}`)
						.then(({ data: { data: payload } }) => {
							resolve(payload)
						})
						.catch(reject)
				}
			})
		},
		[]
	)

	const respondToRating = useCallback(
		/**
		 * @param {RespondToRatingPayload} payload
		 */
		({ _id: rating_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'submitting rating response...',
					key: rating_id
				})

				axios
					.put(`/admin/child-ratings/${rating_id}`, payload)
					.then(({ data: { data: ratingData } }) => {
						message.success({
							content: 'your response submitted successfully',
							key: rating_id
						})
						enableUpdateStatistics()
						fetchRatings(qs.parse(search))
						resolve(ratingData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to submit your response',
							key: rating_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchRatings, search]
	)

	return {
		data: data.ratings,
		totalCount: data.totalCount,
		isLoading,
		fetchRatings,
		fetchRating,
		respondToRating
	}
}
